.footer-wrap {
  position: relative;
  width: 100%;
  height: 319px;
  background-color: #333;
  color: #a2a2a2;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  padding-right: 80px;
  padding-top: 150px;

  ul {
    list-style: none;
    text-align: right;
  }
}
