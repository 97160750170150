.module-wrap {
  position: relative;
  text-align: center;
  padding: 0px 70px;
  overflow: visible;

  @include breakpoint(medium only) {
    padding: 0px 50px;
  }

  @include breakpoint(small only) {
    padding: 0px 0px;
  }
}

.module-title {
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 30px 100px;

  .title-text {
    display: inline-block;
    color: #424242;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
  }

  i {
    color: #df5343;
    font-size: 28px;
    margin-left: 10px;
  }

  .title-underline {
    position: absolute;
    top: 55px;
    left: 50%;
    transform: translate(-50%, 0px);
    display: inline-block;
    width: 31px;
    border: 1px solid #df5343;
  }
}

.module-main {
  position: relative;
  display: inline-block;
  max-width: 1000px;
  width: 98%;
  background-color: #deddd5;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  text-align: left;

  @include breakpoint(medium only) {
    display: none;
  }

  @include breakpoint(small only) {
    display: none;
  }

  /* fix round corner overflow error when browser window resized */
  -webkit-transform: scale3d(1,1,1);

  img {
    position: relative;
    z-index: 5;
    top: 0;
    left: 0;
  }

  .main-text {
    position: absolute;
    z-index: 15;
    top: 0;
    right: 0;
    width: 33%;
    height: 100%;
    background-color: #deddd5;
    color: #424242;

    h3 {
      margin: 25px 45px 0px 45px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    p {
      margin: 10px 45px;
      font-size: 15px;
      font-weight: 300;
      line-height: 23px;
    }

    .main-link {
      position: absolute;
      bottom: 25px;
      right: 45px;
      width: 110px;
      height: 30px;
      background-color: #eaeae6;
      border: 1px solid #df5343;
      border-radius: 32px;
      color: #424242;
      font-size: 14px;
      text-align: center;
      padding-top: 4px;
    }
  }
}

.module-slider {
  display: inline-block;
  max-width: 1024px;
  height: 450px;

  .owl-stage-outer{
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
  }

  .owl-item {
    img {
      display: inline-block;
    }
  }

  .item{
    border-radius: 10px;
    height: 350px;
    border: 0px solid red;
    overflow: hidden;
    transition: all 0.3s;
    background-color: #deddd5;
    cursor: pointer;
    margin: 10px;

    @include breakpoint(medium only) {
      border-radius: 10px;
      margin: 10px;
      height: 320px;
    }

    @include breakpoint(small only) {
      border-radius: 0px;
      margin: 0px;
      height: 350px;
      background-color: transparent;
    }

    div, img {
      position: relative;
      width: 100%;
    }

    img {
      max-width: 380px;
      //max-height: 250px;
    }

    .item-title {
      line-height: 20px;
      padding: 25px 20px 10px 20px;
      color: #424242;
      font-size: 20px;
      font-weight: 300;
      text-align: left;

      &.product-title {
        text-align: center;
      }
    }

    .item-price {
      color: #df5343;
      font-size: 26px;
      font-weight: 700;
      line-height: 39px;
    }

    .item-description {
      height: 68px;
      font-size: 15px;
      font-weight: 300;
      line-height: 23px;
      padding: 0px 20px;
      border: 0px solid red;
      margin-top: 340px;
      text-align: left;

      &.product-description {
        text-align: center;
        padding-top: 40px;
      }
    }

    .item-link {
      padding: 5px 15px;
      background-color: #eaeae6;
      border: 1px solid #df5343;
      border-radius: 32px;
      color: #424242;
      font-size: 14px;
      text-align: center;
    }

    .mask-wrap {
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 555;
      opacity: 0;
      transition: all $transition-duration;
    }
    .mask-wrap:after {
      padding-top: 59.524%;
      display: block;
      content: '';
    }
    .mask {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(0,0,0,0.5);
      border-radius: 10px 10px 0 0;

      @include breakpoint(medium only) {
        border-radius: 10px 10px 0 0;
      }

      @include breakpoint(small only) {
        border-radius: 0px;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #edede8;
        font-size: 68px;
      }
    }
  }

  .item:hover {
    height: 400px;
    transform: translate(0px, -40px);
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    background-color: #edede8;

    @include breakpoint(medium only) {
      height: 400px;
    }

    @include breakpoint(small only) {
      height: 430px;
    }

    .item-description {
      margin-top: 0px;
    }

    .mask-wrap {
      opacity: 1;
    }
  }


  .owl-nav > div {
    position: absolute;
    z-index: 300;
    top: 0;
    width: 100px;
    height: 100%;
  }

  .owl-nav .btn-wrap{
    width: 100%;
    height: 100%;

    i {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #df5343;
      font-size: 24px;
    }
  }

  .owl-nav .owl-prev{
    left: -70px;

    @include breakpoint(medium only) {
      left: -65px;
    }

    @include breakpoint(small only) {
      left: 0px;
    }

    i {
      left: -10px;
    }
  }

  .owl-nav .owl-next{
    right: -70px;

    @include breakpoint(medium only) {
      right: -65px;
    }

    @include breakpoint(small only) {
      right: 0px;
    }

    i {
      right: -10px;
    }
  }
}
