.page-title {
  background-color: #424242;
  color: white;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.03px;
  line-height: 66px;
  padding-left: 80px;
  margin-top: 80px;

  @include breakpoint(medium down) {
    padding-left: 40px;
  }

  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  &.active {
    font-weight: 400;
    letter-spacing: 1px;
  }
}

.page-content, .page-content-single {

  @include breakpoint(medium down) {
    padding-right: 0px;
  }

  &.row {
    //max-width: 1840px;
  }

  div {
    //border: 1px solid red;
  }

  .title {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.85px;
    line-height: 23px;
    margin: 0;

    &.with-margin {
      margin-top:30px;
      margin-bottom:18px;
    }
  }

  .title-large {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.14px;
    line-height: 30px;
  }

  .title-xlarge{
    color: #424242;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
  }

  .super-title {
    color: black;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }

  .text {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.85px;
    line-height: 25px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .text-group {
    margin-bottom: 20px;
  }

  .text-large {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1.03px;
    line-height: 18px;
  }

  .digit-xlarge {
    color: #df5343;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
  }

  img {
    //margin-top: 10px;
    //margin-bottom: 10px;
  }
}

.page-content-single {
  padding: 0px;
}

.page-content-inner {
  padding-top: 50px;
  padding-left: 80px;
  padding-right: 80px;

  @include breakpoint(medium down) {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.page-sidebar {
  display: block;
  padding-top: 50px;

  @include breakpoint(medium down) {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    border-bottom: 1px solid #909090;
  }
}

.rwd-pagetitle {
  display: none;
  color: #df5343;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  padding: 20px 40px;
  border-bottom: 1px solid #909090;
  cursor: pointer;

  &::after {
    content: "\25bc";
    font-size: 10px;
    vertical-align: top;
  }

  @include breakpoint(medium down) {
    display: block;
  }
}

.rwd-ddl {
  display: none;

  .rwd-ddl-item {
    display: block;
    line-height: 30px;
    padding: 7px 40px;
    border-bottom: 1px solid #909090;
    border-left: 1px solid #909090;
    border-right: 1px solid #909090;
    background-color: #deddd5;
    color: #424242;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1.1px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      background-color: #edede8;
    }

    &:first-child {
      border-top: 1px solid #909090;
    }
  }
}

.rwd-ddl-select {
  display: inline-block;
  margin-right: 0px;
}

.page-main-content {
  padding-top: 50px;
  padding-bottom: 60px;
  padding-right: 80px;

  @include breakpoint(medium down) {
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.page-content-title, .rwd-page-content-title {
  position: relative;
  display: block;
  text-align: left;
  padding-bottom: 60px;

  .title-text {
    display: inline-block;
    color: #424242;
    //font-size: 25px;
    //line-height: 38px;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1.2px;
    line-height: 40px;
  }

  .title-underline {
    position: absolute;
    top: 70px;
    left: 0px;
    display: inline-block;
    width: 31px;
    border: 1px solid #df5343;

    @include breakpoint(medium down) {
      border: 0px solid #df5343;
    }
  }
}

.page-content-title {
  &.product-page {
    display: none;
  }

  @include breakpoint(medium down) {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #909090;
    margin-bottom: 20px;
  }
}

.rwd-page-content-title {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid #909090;
}

.sidebar-item-folder {
  margin-bottom: 10px;

  .item-folder {
    display: none;
  }
}

.sidebar-title {
  display: block;
  line-height: 25px;
  padding-left: 65px;
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
  color: #373d4a;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.14px;
  line-height: 34px;

  @include breakpoint(medium down) {
    padding-left: 35px;
  }

  &.has-children::after {
    content: ' +';
  }

  &.has-children.open::after {
    content: ' -';
  }
}

.sidebar-title-orderlist {
  display: block;
  line-height: 25px;
  padding-left: 65px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.14px;
}

.sidebar-item {
  display: block;
  line-height: 25px;
  padding-left: 65px;
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;

  @include breakpoint(medium down) {
    padding-left: 40px;
  }

  &.active {
    color: #df5343;
  }
}

.price-table {
  border: 1px solid #909090;
  border-collapse: collapse;

  thead, tbody, tr {
    //background-color: transparent;
    background-color: #f1e8de;
    //border: none;
  }

  th, tr:nth-child(even) {
    background-color: #e4e6de;
  }

  th, td {
    padding: 5px 25px;
    text-align: center;
    border: 1px solid #909090;
  }
}


//== General ==
//================================
.btn-class {
  display: inline-block;
  width: 158px;
  height: 42px;
  line-height: 42px;
  border-radius: 42px;
  color: #eaeae6;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;

  &:hover, &:active, &:visited, &:link  {
    color: #eaeae6;
  }
}

.link-btn {
  display: inline-block;
  color: #df5343;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline;
  margin-left: 10px;
  margin-right: 10px;
}

.warning {
  color: #df5343;
}

.cornered-img {
  border-radius: 10px;
}

.red-border-right {
  border-right: 2px solid #df5343;
}

.gray-hr {
  border-color: #979797;

  &.rent-info {
    display: none;

    @include breakpoint(medium down) {
      display: block;
    }
  }
}

.align-right {
  text-align: right;
}

.color-orange {
  color: #df5343;
}

//== Form item ==
//================================
.form-item {
  margin: 10px 0;

  &.regular-search {
    width: auto;
  }

  label{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    &.middle {
      padding: 7px 0;
    }
  }

  div {
    line-height: 35px;
  }

  input[type='text'], input[type='password'], select {
    display: inline-block;
    width: 250px;
    height: 35px;
    border-radius: 5px;
    margin: 0;
    padding: 0 0 0 15px;

    @include breakpoint(medium down) {
      width: 180px;
    }

    &#reg-addr {
      width: 408px;
    }
  }

  select {
    &#reg-bmonth, &#reg-bday, &#reg-byear {
      width: 120px;
    }
  }

  input, select {
    border: 1px solid #979797;
  }

  input, input:focus, input:active,
  select, select:focus, input:active {
    background-color: transparent;
  }

  input::placeholder {
    line-height: 18px;
  }

  select option:disabled {
    color: #909090;
  }

  option[value=””][disabled] {
    display: none;
  }
}

.memo {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}


//== Register page ==
//================================
.reg-save {
  background-color: #df5343;
  margin-top: 40px;
  margin-bottom: 60px;
}


//== Update page ==
//================================
.update-save {
  background-color: #df5343;
  margin-top: 40px;
  margin-bottom: 60px;
}

#input-memberID-div {
  line-height: 42px;
  padding-top: 4px;
  margin-bottom: 15px;
}

.status-update {
  background-color: #df5343;
  margin-top: 65px;
  margin-bottom: 130px;
}


//== Regular search ==
//================================
.regular-search-hr {
  display: inline-block;
  height: 2px;
  width: 251px;
  border-top: 1px solid #df5343;
  margin-top: 40px;
  margin-bottom: 30px;
}



//== info page ==
//================================
.rent-img-wrap {
  display: none;

  @include breakpoint(medium down) {
    display: block;
  }
}

.rent-info-col {
  text-align: center;

  div, img, p {
    vertical-align: top;
  }

  @include breakpoint(medium down) {
    text-align: left;
    margin-bottom: 20px;
  }

  &.long {
    @include grid-column(1 of 5);

    @include breakpoint(medium down) {
      @include grid-column(100%);
    }
  }

  &.short {
    @include grid-column(14%);

    @include breakpoint(medium down) {
      @include grid-column(100%);
    }
  }

  &.arrow {
    @include grid-column(1 of 35);

    @include breakpoint(medium down) {
      display: none;
    }

    i {
      color: #909090;
      line-height: 55px;
    }

    .separator {
      width: 1px;
      height: 550px;
      border: 1px solid #deddd5;
      margin-top: 30px;
    }
  }

  &.end {
    @include grid-column-end;
  }

  .step-title {
    display: inline-block;
    vertical-align: top;
    margin-top: 16px;
    margin-left: 8px;

    @include breakpoint(medium down) {
      &::after {
        font-size: 20px;
        font-weight: 500;
        //content: ' +';
      }

      &.open::after {
        font-size: 20px;
        font-weight: 500;
        //content: ' -';
      }
    }
  }
}

.rent-info-title {
  @include breakpoint(medium down) {
    //cursor: pointer;
  }
}

.step-unit {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;

  @include breakpoint(medium down) {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &.empty {
    height: 50px;

    @include breakpoint(medium down) {
      display: none;
    }
  }

  img {
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.rwd-text {
  display: none;

  .text {
    color: #424242;
  }

  @include breakpoint(medium down) {
    display: block;
  }
}

.rwd-desktop {
  @include breakpoint(medium down) {
    display: none;
  }
}


//== FAQ page ==
//================================
.faq-item {
  cursor: pointer;

  .super-title::before {
    font-size: 20px;
    font-weight: 500;
    content: '+ ';
  }
  .super-title.open::before {
    font-size: 20px;
    font-weight: 500;
    content: '- ';
  }
}
.faq-answer {
  display: none;
  padding-left: 20px;

  .text {
    margin-top: 0px;
  }
}



//== Construction page ==
//================================
.construction-div {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 160px;
}



//== New ==
//================================
.airport-info .block-title{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.14px;
  line-height: 30px;
}
.airport-info .block-image{
  border: 1px solid #979797;
  margin-bottom: 20px;
}
.airport-info .block-content{
  font-family: "Noto Sans CJK JP";
  font-size: 16px;
  line-height: 18px;

  /* Text style for "位置" */
  font-weight: 400;
  letter-spacing: 1.03px;
  margin-bottom: 20px;
}
.latest-news .module-main .block-image{
  width: 66.6666%;
  background-position:50% 50%;
  background-repeat:no-repeat;
  height: 350px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.latest-news .module-slider .block-image{
  background-position:50% 50%;
  background-repeat:no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 191px;
}



.section-info{
  color: black;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.14px;
    line-height: 30px;
    padding-bottom: 40px;
}
.section-title{
  border-bottom: 1px solid #979797;
  padding-bottom: 20px;
}
.section-item.sub-title{
  font-size: 20px;
}
.section-item{
  font-size: 16px;
  padding: 5px 0;
}


/* mobile by rex */
@media screen and (max-width: 480px) {

  .product-tags-wrap, .rwd-product-tags-wrap {
    margin-bottom: 20px;
  }

  .rwd-product-tags-wrap{
    display: block!important;
  }

  .tag-cat-title{
    display: block;
  }

  .product-tag{
    margin-bottom: 5px;
    margin-right: 5px;
  }

  #detail-num-input{
    width: 50px;
  }

  .add-to-cart-submit{
    transform: scale(0.8);
  }

  #sticky-col{
    position: initial !important;
    display: block;
    min-width: 100%!important;
    max-height: none !important;
  }

  #sticky-footer{
    position: relative!important;
  }

  #sticky-col .product-wrap{
    max-height: none !important;
  }
}
