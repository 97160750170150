#main-slider {
  display: block;
  margin-bottom: 30px;
  margin-top: 0px;

  @include breakpoint(medium down) {
    height: 400px;
  }

  .item img {
    display: block;
    width: 100%;
    max-width: none;
    min-height: 600px;

    @include breakpoint(medium down) {
      min-height: 400px;
    }
  }

  .item {
    position: relative;
    height: 600px;
    overflow: hidden;

    @include breakpoint(medium down) {
      height: 400px;
    }
  }

  .text-div {
    position: absolute;
    z-index: 50;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    color: white;
    border: 0px solid red;
  }

  .text-center {
    font-size: 46px;

    @include breakpoint(medium only) {
      font-size: 36px;
    }

    @include breakpoint(small only) {
      font-size: 26px;
    }
  }

  .subtext-center {
    font-size: 24px;
    margin-top: 50px;

    @include breakpoint(medium down) {
      font-size: 20px;
    }
  }

  .subtitle-bottom {
    font-size: 20px;
    top: auto;
    bottom: 4%;
    transform: translateY(0%);

    @include breakpoint(medium down) {
      font-size: 16px;
    }
  }


  .owl-nav > div {
    position: absolute;
    z-index: 300;
    top: 0;
    width: 80px;
    height: 100%;
  }

  .owl-nav .btn-wrap{
    width: 100%;
    height: 100%;

    i {
      position: relative;
      top: 50%;
      left: 40%;
      transform: translateY(-50%);
      font-size: 34px;
      color: white;

      @include breakpoint(medium down) {
        font-size: 24px;
      }
    }
  }

  .owl-nav .owl-prev{
    left: 0;
  }

  .owl-nav .owl-next{
    right: 0;
  }
}


.cell-wrap {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;

  div {
    display: inline-block;
    border: 1px solid blue;
    color: white;
    text-align: center;
  }
}
