.partner-wrap {
  //height: 370px;
  background-color: #deddd5;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 60px;
  margin-top: 80px;
}

#partner-slider {
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  .owl-item {
    img {
      display: inline-block;
      width: auto;
    }
  }

  .owl-nav > div {
    position: absolute;
    z-index: 300;
    top: 0;
    width: 100px;
    height: 100%;
  }

  .owl-nav .btn-wrap{
    width: 100%;
    height: 100%;

    i {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #df5343;
      font-size: 24px;
    }
  }

  .owl-nav .owl-prev{
    left: -50px;

    i {
      left: -10px;
    }
  }

  .owl-nav .owl-next{
    right: -50px;

    i {
      right: -10px;
    }
  }
}

.partner-icon {
  width: 92px;
  height: 92px;
  border: 1px solid #df5343;
  border-radius: 50%;
}

.partner-name {
  color: #424242;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-top: 10px;
}
