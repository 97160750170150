.login-bg {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  transition: all $transition-duration;
  -webkit-animation-duration: $bg-transition-duration;
}

.login-wrap, .forgot-wrap {
  position: absolute;
  z-index: 1500;
  display: inline-block;
  //margin-top: 5px;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
  border: 0px solid green;
  //vertical-align: top;
  text-align: center;
  transition: all $transition-duration;
  -webkit-animation-duration: $bg-transition-duration;

  /*
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  */
}

.login-close, .forgot-close {
  color: #eaeae6;
  font-size: 24px;
  line-height: 24px;
  text-align: right;

  i {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
  }
}

.login-box, .forgot-box {
  display: inline-block;
  width: 300px;
  background-color: rgba(234,234,230,0.9);
  border-radius: 10px;

  a {
    color: #424242;
    text-decoration: underline;

    &:hover{
      color: darken(#424242, 10%);
    }
  }

  div {
    border: 0px solid blue;
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    width: 250px;
    height: 42px;
    border-radius: 30px;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 4px;
    margin-bottom: 4px;

    i {
      margin-right: 5px;
    }
  }
}

.login-box {
  padding-bottom: 30px;
}

.forgot-box {
  position: relative;
  padding-bottom: 30px;
}

.login-title, .forgot-title {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.memo-text {
  color: #424242;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.forgot-back-btn {
  position: absolute;
  top: 2px;
  left: 0px;
  width: 65px;
  height: 45px;
  line-height: 45px;
  color: #df5343;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.title-hr {
  border: 1px solid #df5343;
  margin-right: 25px;
  margin-left: 25px;
}

#login-form, #forgot-form {
  input {
    margin: 0;
  }
}

.login-form-elm {
  text-align: left;
  padding-left: 28px;
  margin-top: 13px;
  color: #909090;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  input {
    display: inline-block;
    width: 200px;
    font-size: 16px;
    line-height: 22px;
    box-shadow: none;
    background-color: transparent;
    border: 0px;

    &:hover, &:focus{
      box-shadow: none;
      background-color: transparent;
      border: 0px;
    }
  }
}

.elm-hr {
  border-top: 1px solid #909090;
  margin-right: 25px;
  margin-left: 25px;
}

.login-helper {
  text-align: left;
  padding-left: 28px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .link-forgot-password {
    float: right;
    padding-right: 28px;
  }
}

.login-submit, .forgot-submit {
  background-color: #df5343;
}

.link-reg {
  background-color: #20acbc;
}

.login-weibo {
  background-color: #e69700;
}

.login-wechat {
  background-color: #37c940;
}

.spliter-or {
  line-height: 40px;

  hr {
    position: relative;
    top: -5px;
    display: inline-block;
    width: 20px;
    border-top: 1px solid #909090;
    margin-left: 10px;
    margin-right: 10px;
  }

  span {
    color: #424242;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
}

hr.footer-hr {
  margin-top: 15px;
  border-top: 1px solid #909090;
}

.login-footer {
  text-align: left;
  padding-left: 28px;
  padding-top: 15px;
  padding-bottom: 15px;

  .link-reg {
    padding-left: 14px;
  }
}
