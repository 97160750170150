.order-thankyou {
  text-align: center;
}

.product-row, .subtotal-row {
  border-top: 1px solid #979797;
  padding: 20px 0px;

  .column {
    padding-left: 0px;
    padding-right: 0px;
  }

  .thumb {
    float: left;
    margin-top: 23px;
    margin-right: 20px;
    width: 76px;
    border-radius: 10px;
  }

  .subtitle {
    color: #424242;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
  }

  .subtext {
    text-align: left;
    margin: 0;
    color: #424242;
    font-size: 15px;
    font-weight: 300;
    line-height: 40px;
  }

  .order-amount-input {
    margin-left: 20px;
    width: 71px;
    height: 25px;
    background-color: #edede8;
    border: 1px solid #979797;
    border-radius: 5px;
    color: #424242;
    font-size: 15px;
    font-weight: 300;
    line-height: 23px;
    text-align: center;

    @include breakpoint(medium down) {
      margin-left: 10px;
      width: 50px;
    }
  }

  .price {
    text-align: right;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.82px;
    line-height: 20px;
    margin: 10px 0px;
  }

  .detail-div {
    border-right: 1px solid #979797;
  }
}

.subtotal-row, .shipping-row {
  .subrow {
    display: block;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: left;

    span {
      margin: 0;
    }
  }
}

.total-row {
  border-top: 1px solid #df5343;

  .subrow {
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: left;
  }

  .subtitle {
    color: #424242;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 28px;
  }

  .price {
    color: #df5343;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1.32px;
    line-height: 33px;
  }
}

.button-row {
  margin-top: 40px;
  margin-bottom: 120px;

  .first-col {
    border-right: 2px solid #909090;
  }
}

.print-btn, .back-home-btn {
  text-align: center;
  background-color: #df5343;
  color: #eaeae6;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-right: 10px;
}

.print-btn {
  @include breakpoint(medium down) {
    display: none;
  }
}

.shipping-row {
  .subrow {
    display: block;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: left;

    .price {
      margin-left: 120px;
    }
  }
}

.remove-btn {
  display: block;
  text-align: right;
  border: 0px solid red;
  color: #909090;
  padding: 10px 0px;
  margin-top: 20px;
}

//== Order1 ==
//=============================
.order-col {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;

  .title-xlarge {
    line-height: 38px;
  }

  &.sticky {
    position: fixed;
    top: 0;
    padding-left: 40px;
    padding-right: 94px;
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.rwd-order-col {
  display: none;
  padding-left: 10px;
  padding-right: 20px;

  @include breakpoint(medium down) {
    display: block;
  }
}

.order-col-second {
  padding-left: 80px;

  @include breakpoint(medium down) {
    padding-left: 20px;
  }
}

.coupon-row {
  border-top: 1px solid #979797;
  padding: 20px 0px;

  .form-item {
    height: 40px;
    display: inline;
    margin: 0;
  }

  .col {
    display: inline-block;
    height: 45px;
    vertical-align: top;
    margin-top: 3px;

    &.first {
      margin-right: 0px;
      width: 30%;
    }

    &.second {
      padding-top: 4px;
      width: 40%;
    }

    &.third {
      width: 80px;
      text-align: right;
    }
  }

  label.title-large {
    line-height: 43px;
  }
}

.coupon-btn {
  width: 70px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #df5343;
  border-radius: 42px;
  letter-spacing: 1px;
  margin-top: 3px;
}

#coupon-input {
  width: 100%;
  height: 35px;
  background-color: #edede8;
  border: 1px solid #979797;
  border-radius: 5px;
  padding-left: 10px;
}

#order1-form {
  padding-bottom: 30px;

  .form-item.first {
    margin-top: 30px;
  }
  .form-item.last {
    margin-bottom: 30px;
  }
}

.order1-next {
  width: 106px;
  height: 42px;
  background-color: #df5343;
  border-radius: 42px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 60px;
}


//== order2 ==
//========================
.title-note {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.multi-title-line {
  margin-bottom: 20px;

  .second-col {
    padding-top: 4px;
  }
}

select.date-select {
  width: 200px;
}

select.hr-select {
  width: 100px;
  margin-left: 10px;
}

select.min-select {
  width: 100px;
}

.time-col {
  margin-left: 5px;
  margin-right: 5px;
}

.order2-pay {
  width: 106px;
  background-color: #df5343;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.continue-shopping {
  background-color: #df5343;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
  vertical-align: top;
}



//== parsley js validation ==
//================================
span.parsley-success,
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
    color: #468847;
    background-color: #F2F9F0 !important;
    //border: 1px solid #D6E9C6;
}

span.parsley-error,
input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    color: #B94A48;
    background-color: #F9F0F0 !important;
    //border: 1px solid #f09784;
}

.parsley-errors-list {
    list-style-type: none;
    opacity: 0;
    transition: all .3s ease-in;

    color: #d16e6c;
    margin-top: 5px;
    margin-bottom: 0;
  padding-left: 0;
}

.parsley-errors-list.filled {
    opacity: 1;
}


/*== order list ==*/
/*==================================*/
.orderlist-date {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.65px;
}

.order-table {
  .first {
    padding-left: 0px;
    padding-right: 0px;
  }

  .second, .third {
    text-align: center;
  }

  .last {
    text-align: right;
    padding-left: 0px;
    padding-right: 0px;
  }

  .table-title {
    color: black;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.14px;
    line-height: 30px;
    padding-bottom: 20px;

    .second, .third, .fouth, .last {
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }
  .table-row {
    color: #424242;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.82px;
    line-height: 45px;
    border-top: 1px solid #979797;
    padding-top: 20px;
    padding-bottom: 20px;

    img {
      width: 45px;
      height: 45px;
      border-radius: 10px;
      margin-right: 15px;
    }

    .last {
      border-left: 1px solid #979797;
    }

    .desktop {
      display: block;

      @include breakpoint(medium down) {
        display: none;
      }
    }

    .rwd {
      display: none;

      @include breakpoint(medium down) {
        display: block;
      }

      img {
        width: 100%;
        height: auto;
        margin: 0;
      }

      .rwd-col {
        line-height: 22px;
      }

      .last {
        height: 100%;
      }

      .date-col {
        letter-spacing: -0.4px;
      }

    }
  }

  .ordersubtotal-row {
    .first {
    }
    .last {
      border: 0px;
    }
  }

  .ordertotal-row {
    color: #424242;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.82px;
    line-height: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #df5343;

    .ordertotal-title {
      color: black;
      margin-right: 15px;
    }

    .total-title {
      text-align: right;

      @include breakpoint(medium down) {
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
      }

      span {
        line-height: 45px;
      }
    }

    .first {
      @include breakpoint(medium down) {
        display: none;
      }
    }

    .last {
      color: #df5343;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 1.32px;
    }
  }
  .table-button {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: -15px;

    .line-div {
      display: inline-block;
      width:  1px;
      height: 40px;
      border: 1px solid #909090;
      vertical-align: bottom;
      margin-bottom: 5px;

      @include breakpoint(medium down) {
        display: none;
      }

    }

    .first {
      text-align: right;
      padding-right: 10px;
    }

    .last {
      text-align: right;
      border-left: 1px solid #909090;
    }

    .search-btn, .back-home-btn, .order-paid-btn, .order-pay-btn {
      margin: 0;
      background-color: #df5343;
      color: #eaeae6;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: center;

      @include breakpoint(medium down) {
        width: 140px;
      }
    }

    .search-btn, .back-home-btn {
      margin-right: 10px;
      border: 1px solid #df5343;
    }

    .order-cancel-btn {
      margin: 0;
      background-color: transparent;
      color: #df5343;
      border: 1px solid #df5343;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: center;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

// for the row in orderlist page
.section-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
