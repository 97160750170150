* {
  font-family: "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
}

.header {
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  //background-color: rgba(255,255,255,0.5);
  background-image: url('../img/gradient.png');
  background-size: cover;

  .row {
    max-width: 100%;
  }

  .columns {
    line-height: 80px;
  }
}

.burger-div {
  @include hamburger($primary-color, $primary-color, 30px, 25px, 4px, 2px, 3);

  margin-left: 30px;

  &::after {
    top: -6px;
  }

  span {
    font-size: 12px;
    color: #df5343;
    position: absolute;
    top: -12px;
    display: none;
  }

  @include breakpoint(medium down) {
    margin-left: 10px;

    span {
      display: block;
    }
  }
}

.logo {
  width: 146px;
}

.rwd-info-row {
  display: none;
  width: 80%;
  //border: 1px solid red;
  margin-left: auto;
  margin-right: auto;

  .item-count {
    margin-left: 5px;
  }

  .right-border {
    border-right: 1px solid white;
  }

  .separater-div {
    display: inline-block;
  }

  .icons-wrap {
    padding-top: 20px;
    > div {
      display: inline-block;
      margin-right: 10px;
    }

    div.last {
      margin-right: 0;
    }

    i.fa {
      margin-right: 5px;
    }
  }

  @include breakpoint(medium down) {
    display: block;
  }
}

.rwd-fb-div {
  display: none;
  width: 28px;
  margin: 0;
  padding: 0;
  text-align: center;

  i, div {
    display: inline-block;
    color: #3b5998;
    line-height: 25px;
    font-size: 25px;
    vertical-align: middle;
  }

  @include breakpoint(medium down) {
    display:inline-block;
  }
}

.fb-div {
  display:inline-block;
  width: 40px;
  line-height: 35px;
  margin: 0 10px;
  padding: 0;
  text-align: center;

  i, div {
    display: inline-block;
    color: #3b5998;
    font-size: 35px;
    vertical-align: middle;
  }

  @include breakpoint(medium down) {
    display: none;
  }

  &:hover {
    i {
      color: darken(#3b5998, 10%);
    }
  }
}

.reserve-div {
  width: 90px;
}

.cart-div {
  margin-left: 10px;
  margin-right: 10px;
  width: 130px;

  i, div {
    display: inline-block;
    color: $primary-color;
    line-height: 35px;
    vertical-align: middle;
  }
}

.rwd-cart-div {
  margin-right: 10px;
  display: none;

  @include breakpoint(medium down) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  i, div {
    display: inline-block;
    color: $primary-color;
    line-height: 35px;
    vertical-align: middle;
  }
}

.profile-div {
  margin-right: 10px;
  width: 90px;
  max-width: 100px;

  i, div {
    display: inline-block;
    color: $primary-color;
  }

  .login-text {
    max-width: 60px;
    line-height: 35px;
    height: 35px;
    overflow: hidden;
    vertical-align: bottom;
  }
}

.header-link {
  display: inline-block;
  text-align: center;
  line-height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid $primary-color;
  border-radius: 30px;

  &:hover {
    border: 1px solid darken($primary-color, 10%);
    background-color: rgba(220, 220, 220, 0.2);

    i, div {
      color: darken($primary-color, 10%);
    }
  }
}

.header-link {
  @include breakpoint(medium down) {
    display: none;
  }
}


.profile-menu, .rwd-profile-menu {
  position: absolute;
  z-index: 5555;
  top: 90px;
  right: 40px;
  display: inline-block;
  width: 180px;
  height: 138px;
  background-color: #deddd5;
  border-radius: 8px;

  .menu-triangle {
    position: absolute;
    z-index: -5;
    top: -12px;
    right: 25px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 15px solid #deddd5;
  }

  .menu-item {
    position: relative;
    z-index: 5;
    display: block;
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 300;
    line-height: 45px;
    border-bottom: 2px solid #909090;

    &:hover {
      background-color: #edede8;
    }

    &.first:hover {
      border-radius: 8px 8px 0px 0px;
    }

    &.last:hover {
      border-radius: 0px 0px 8px 8px;
    }

    &.last {
      border-bottom: 0px solid #909090;
    }
  }
}

.rwd-profile-menu {
  top: 98px;
  right: 13.7%;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}



/*== Menu list
==========================*/

.menu-bg {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  transition: all $transition-duration;
}

.menu-wrap {
  position: absolute;
  z-index: 1500;
  display: inline-block;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
  border: 0px solid green;
  //vertical-align: top;
  text-align: center;
  transition: all $transition-duration;

  /*
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  */
}

.menu-close {
  position: relative;
  color: #eaeae6;
  font-size: 24px;
  line-height: 24px;
  text-align: right;

  i {
    position: relative;
    top: 35px;
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    cursor: pointer;

    @include breakpoint(medium down) {
      top: 10px;
      right: 60px;
    }
  }
}

.menu-box {
  display: inline-block;
  max-width: 550px;
  min-width: 320px;
  background-color: rgba(234,234,230,0.0);
  border-radius: 10px;
  color: #eaeae6;

  a {
    color: #eaeae6;
    text-decoration: none;

    &:hover{
      color: darken(#eaeae6, 20%);
    }

    &.active {
      color: #df5343;
    }
  }

  div {
    border: 0px solid blue;
  }

  hr {
  }

  button {
    width: 250px;
    height: 42px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 4px;
    margin-bottom: 4px;

    i {
      margin-right: 5px;
    }
  }

  .logo-taiwan {
    display: inline-block;

    @include breakpoint(medium down) {
      display: none;
    }
  }
}


.menu-language {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.language-separator {
  margin-left:2px;
  margin-right:2px;
}

.menu-site, .menu-customer {
  padding-top: 20px;

  @include breakpoint(medium down) {
    padding-top: 0px;
  }

  a {
    margin: 15px;
  }
}

.menu-subtitle {
  color: #eaeae6;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
}

.menulink-item {
  color: #eaeae6;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;

  @include breakpoint(medium down) {
    display: block;
    padding-top: 10px;

    &+span {
      display: none;
    }
  }
}

.menu-hr {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 30px;
  border: 1px solid #eaeae6;
}
