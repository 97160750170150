#products-slider, #news-slider {
  display: inline-block;
}

.product-tags-wrap {
  @include breakpoint(medium down) {
    display: none;
  }
}

.rwd-product-tags-wrap {
  display: none;
  color: #373d4a;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.9px;
  line-height: 27px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid #909090;

  .row {
    display: inline-block;
    width: 100%;
  }

  .separater-div {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    width: 1px;
    height: 25px;
    border-left: 1px solid #909090;
  }
}

.product-tag {
  display: inline-block;
  width: 88px;
  height: 35px;
  margin-right: 15px;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #df5343;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.9px;
  line-height: 34px;
  text-align: center;
  color: #373d4a;

  &.selected {
    background-color: #df5343;
    color: #edede8;
    font-weight: 500;

    &:link, &:hover, &:visited, &:active {
      color: #edede8;
    }
  }
}

.rwd-product-tag {
  display: inline-block;
  width: 70px;
  height: 35px;
  background-color: #df5343;
  border: 1px solid #df5343;
  border-radius: 30px;
  color: #edede8;
  font-weight: 500;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.9px;
  line-height: 34px;
  text-align: center;
  margin-left: 10px;

  &:link, &:hover, &:visited, &:active {
    color: #edede8;
  }
}

.product-cat {
  display: inline-block;
  margin-right: 20px;
  //padding-left: 30px;
  //margin-left: 20px;
  ///border-left: 1px solid #909090;
}

.product-pool {
  margin-bottom: 60px;

  .column {
    text-align: center;
  }
}

.product-item {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  height: 310px;
  //width: 300px;
  border: 0px solid red;
  overflow: hidden;
  transition: all 0.3s;
  background-color: #deddd5;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;

  div, img {
    position: relative;
    width: 100%;
  }

  img {
    max-width: 320px;
    //max-height: 200px;
  }

  .item-title {
    padding: 25px 20px 10px 20px;
    color: #424242;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;

    .product-name {
      font-weight: 500;
    }
  }

  .item-price {
    color: #df5343;
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
  }

  .mask-wrap {
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 555;
    opacity: 0;
    transition: all $transition-duration;
  }

  .mask-wrap:after {
    padding-top: 59.524%;
    display: block;
    content: '';
  }
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 10px 10px 0 0;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #edede8;
      font-size: 68px;
    }
  }
}

.product-item:hover {
  .mask-wrap {
    opacity: 1;
  }
}


// == Product Detail ==
// ========================

.product-detail-title {
  position: relative;
  display: inline-block;
  text-align: center;
  padding-bottom: 0px;

  .title-text {
    display: inline-block;
    color: #424242;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;

    @include breakpoint(medium down) {
      font-size: 24px;
    }
  }
}

.product-main-img {
  padding-bottom: 20px;
  width: 420px;

  @include breakpoint(small down) {
    width: 90%;
    text-align: center;
  }
}

.product-detail-thumbs {
  width: 420px;
  text-align: left;

  img {
    width: 76px;
    margin-right: 5px;
    cursor: pointer;

    &:hover {
      border: 2px solid #df5343;
    }

    &:last-child {
      margin-right: 0px;
    }

    @include breakpoint(medium down) {
      width: 14%;
    }
  }
}

.red-underline {
  width: 31px;
  border: 1px solid #df5343;
  margin-top: 40px;
  margin-bottom: 55px;

  @include breakpoint(medium down) {
    margin-top: 20px;
    margin-bottom: 35px;
  }
}

.detail-div {
  text-align: left;

  div {
    padding: 0;
    line-height: 50px;
  }
}

.price-detail {
  display: block;
  color: #424242;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 1.38px;

  .separater {
    margin-right: 30px;
    float: right;
  }


  @include breakpoint(medium down) {
    font-size: 22px;
  }
}

.num-wrap {
  div {
    padding-top: 10px;
  }

  label {
    height: 35px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 35px;
    margin: 0;
    padding: 0;
    text-align: center;

    @include breakpoint(medium down) {
      font-size: 18px;
    }
  }

  input, input:focus, input:active, {
    width: 80px;
    height: 35px;
    background-color: #edede8;
    border: 1px solid #979797;
    border-radius: 5px;
    margin: 0;
    text-align: center;
  }

}

.add-to-cart-submit {
  background-color: #df5343;
  letter-spacing: 0px;
  width: 90%;
  max-width: 158px;
}

.product-detail-content {
  padding-top: 10px;
  padding-bottom: 70px;
  color: #424242;

  .title {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 30px;
    margin-top:30px;
    margin-bottom:30px;
  }

  .text {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.85px;
    line-height: 25px;
  }

}
